import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./startups_active_detailed.module.scss"

export default function StartupsActiveDetailed() {
  const data = useStaticQuery(graphql`
    query StartupsActiveDetailedQuery {
      allMarkdownRemark(
        filter: { frontmatter: { active: { eq: true } } }
        sort: { fields: frontmatter___sort_order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              active
              logo
              description
              link
              sort_order
            }
          }
        }
      }
    }
  `)

  return (
    <div className="row justify-content-start startups-container">
      {data.allMarkdownRemark.edges.map(({ node }, index) => (
        <a
          href={node.frontmatter.link}
          key={index}
          rel="noreferrer"
          target="_blank"
          className="col-12 col-sm-6 col-md-4 col-lg-3 py-3 px-3 d-flex flex-column text-item startup-container"
        >
          <div className="logo-with-bg">
            <img alt={node.frontmatter.title} src={node.frontmatter.logo} />
          </div>
          <h5 className="mb-2 mt-4">{node.frontmatter.title}</h5>
          <p className="flex-fill">{node.frontmatter.description}</p>
        </a>
      ))}
    </div>
  )
}
