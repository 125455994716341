import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/contact"
import SectionNoBG from "../components/section_no_bg"
import StartupsActiveDetailed from "../components/startups_active_detailed"
import StartupsAlumni from "../components/startups_alumni"

import Fade from "react-reveal/Fade"

import introTexts from "../../content/startups/intro_section.json"
import contactTexts from "../../content/startups/contact.json"

const Startups = () => (
  <Layout pageTitle="Startups">
    <Seo
      title="Startups"
      description="Djäkne Startup Studio builds software companies. Over the years, we have been involved in more than 40 startups. Learn more about the studio."
    />
    <SectionNoBG
      classes="first-section"
      narrow ={true}
      autoHelight="true"
      headline={introTexts.headline}
      text={introTexts.text}
    ></SectionNoBG>
    <Fade bottom duration={1500} fraction={0.1} distance={"200px"}>
      <SectionNoBG>
        <div className="container-xxl flex-grow-1 d-flex flex-column justify-content-center">
          <div className="text-section-text-container col-12 mb-1">
            <h3 className=" mb-4">Active startups</h3>
          </div>
          <StartupsActiveDetailed />
        </div>
      </SectionNoBG>
      <StartupsAlumni />
      <Contact texts={contactTexts} />
    </Fade>
  </Layout>
)

export default Startups
