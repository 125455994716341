import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./startups_active.module.scss"

export default function StartupsAlumni() {
  const data = useStaticQuery(graphql`
    query StartupsAlumniQuery {
      allMarkdownRemark(
        filter: { frontmatter: { active: { eq: false } } }
        sort: { fields: frontmatter___sort_order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              active
              logo
              sort_order
            }
          }
        }
      }
    }
  `)

  return (
    <section className="py-5">
      <div className="container-fluid py-5">
        <div className="container-xxl p-0 p-lg-3">
          <div className="row mb-2 pb-1 pb-md-5 p-0 px-xxl-1">
            <div className="col d-flex flex-row justify-content-between align-items-center">
              <h3 className=" mb-0">Alumni</h3>
            </div>
          </div>
          <div className="container">
            <div className="row gx-4 gy-5 p-0 px-xxl-0 startup-logos-container d-flex align-items-center">
              {data.allMarkdownRemark.edges.map(({ node }, index) => (
                <div
                  key={index}
                  className="col-6 col-md-4 col-lg-2 text-center"
                >
                  <img
                    alt={node.frontmatter.title}
                    src={node.frontmatter.logo}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
